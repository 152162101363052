@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans text-gray-800 dark:text-gray-200 antialiased;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
  
  ::selection {
    @apply bg-primary/20 dark:bg-primary/30;
  }
}

@layer components {
  .container {
    @apply px-4 mx-auto max-w-7xl;
  }
  
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200 inline-flex items-center justify-center;
  }
  
  .btn-primary {
    @apply btn bg-primary hover:bg-primary-dark text-white;
  }
  
  .btn-secondary {
    @apply btn bg-secondary hover:bg-secondary-dark text-white;
  }
  
  .btn-outline {
    @apply btn border-2 border-primary text-primary hover:bg-primary hover:text-white;
  }
  
  .section {
    @apply py-16 md:py-24;
  }
  
  .section-title {
    @apply text-3xl md:text-4xl font-bold mb-8 text-dark-dark dark:text-white;
  }
  
  .form-input {
    @apply w-full px-4 py-2 rounded-md border border-gray-300 dark:border-dark focus:ring-2 focus:ring-primary/50 focus:border-primary dark:bg-dark-light dark:text-white outline-none transition-all duration-200;
  }
  
  .card {
    @apply bg-white dark:bg-dark rounded-xl shadow-md hover:shadow-lg transition-all duration-300;
  }
}

/* Custom Animations */
.key-blink {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-light dark:bg-dark-light;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 dark:bg-gray-600 rounded-full hover:bg-gray-500 dark:hover:bg-gray-500;
}