@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Montserrat:wght@500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans text-gray-800 dark:text-gray-200 antialiased;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-heading;
  }
  
  ::selection {
    @apply bg-primary/20 dark:bg-primary/30;
  }
}

@layer components {
  .container {
    @apply px-4 mx-auto max-w-7xl;
  }
  
  .btn {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200 inline-flex items-center justify-center;
  }
  
  .btn-primary {
    @apply btn bg-primary hover:bg-primary-dark text-white;
  }
  
  .btn-secondary {
    @apply btn bg-secondary hover:bg-secondary-dark text-white;
  }
  
  .btn-outline {
    @apply btn border-2 border-primary text-primary hover:bg-primary hover:text-white;
  }
  
  .section {
    @apply py-16 md:py-24;
  }
  
  .section-title {
    @apply text-3xl md:text-4xl font-bold mb-8 text-dark-dark dark:text-white;
  }
  
  .form-input {
    @apply w-full px-4 py-2 rounded-md border border-gray-300 dark:border-dark focus:ring-2 focus:ring-primary/50 focus:border-primary dark:bg-dark-light dark:text-white outline-none transition-all duration-200;
  }
  
  .card {
    @apply bg-white dark:bg-dark rounded-xl shadow-md hover:shadow-lg transition-all duration-300;
  }
}

/* Custom Animations */
.key-blink {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-light dark:bg-dark-light;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 dark:bg-gray-600 rounded-full hover:bg-gray-500 dark:hover:bg-gray-500;
}

/* Macintosh 128K Theme CSS */

/* Color Variables */
:root {
  --mac-beige: #e8e8d3;
  --mac-darker-beige: #d4d4c8;
  --mac-darkest-beige: #bdbdb2;
  --mac-black: #000000;
  --mac-white: #ffffff;
  --mac-gray: #777777;
  --mac-light-gray: #aaaaaa;
  --mac-screen-bg: #ffffff;
  --mac-shadow: rgba(0, 0, 0, 0.3);
  --mac-window-border: #000000;
  --mac-window-title: #000000;
  --mac-highlight: #000000;
}

/* Dark mode overrides */
.dark {
  --mac-screen-bg: #222222;
  --mac-white: #222222;
  --mac-black: #ffffff;
  --mac-gray: #aaaaaa;
  --mac-light-gray: #666666;
  --mac-window-border: #ffffff;
  --mac-window-title: #ffffff;
  --mac-highlight: #ffffff;
}

/* General Macintosh Theme */
.mac-theme {
  font-family: 'Chicago', 'Pixelated MS Sans Serif', 'Monaco', 'VT323', monospace;
  letter-spacing: 0.5px;
  color: var(--mac-black);
}


/* Menu Bar Container */
.mac-menu-bar {
  display: flex;
  background-color: var(--mac-white);
  border-bottom: 1px solid var(--mac-black);
  height: 20px;
  position: relative;
  z-index: 100;
  font-family: 'Chicago', 'Pixelated MS Sans Serif', 'Monaco', 'VT323', monospace;
  user-select: none;
}

/* Apple Menu */
.mac-apple-menu {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

.mac-apple-logo {
  display: inline-block;
  width: 13px;
  height: 13px;
  position: relative;
}

.mac-apple-logo::before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: var(--mac-black);
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z'/%3E%3C/svg%3E");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

/* Menu Items */
.mac-menu-items {
  display: flex;
  height: 100%;
}

.mac-menu-items span {
  font-size: 12px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

/* Active menu state */
.mac-menu-active {
  background-color: var(--mac-black);
  color: var(--mac-white);
  border-right: 1px solid var(--mac-white) !important;
  border-left: 1px solid var(--mac-white) !important;
}

/* Clock in menu bar */
.mac-menu-clock {
  margin-left: auto;
  font-size: 12px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  height: 100%;
}

/* Menu Dropdown */
.mac-menu-dropdown {
  position: absolute;
  top: 20px;
  background-color: var(--mac-white);
  border: 1px solid var(--mac-black);
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  z-index: 101;
  min-width: 200px;
}

/* Position each dropdown under its menu */
.mac-apple-dropdown {
  left: 0;
}

.mac-file-dropdown {
  left: 20px; /* Width of Apple menu */
}

.mac-edit-dropdown {
  left: 64px; /* Approximate position */
}

.mac-view-dropdown {
  left: 108px; /* Approximate position */
}

.mac-special-dropdown {
  left: 158px; /* Approximate position */
}

/* Menu items in dropdown */
.mac-menu-item {
  padding: 4px 20px 4px 28px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
}

.mac-menu-item:hover:not(.mac-menu-item-disabled) {
  background-color: var(--mac-black);
  color: var(--mac-white);
}

/* Menu checkmark for selected items */
.mac-menu-item-checked::before {
  content: "✓";
  position: absolute;
  left: 10px;
}

/* Disabled menu items */
.mac-menu-item-disabled {
  color: var(--mac-gray);
  cursor: default;
}

/* Menu divider */
.mac-menu-divider {
  height: 1px;
  background-color: var(--mac-black);
  margin: 3px 1px;
}

/* Command key symbols for keyboard shortcuts (optional) */
.mac-command-key::after {
  content: "⌘";
  margin-left: 20px;
  float: right;
}
/* Main Macintosh case */
.mac-computer-frame {
  width: 95vw;
  max-width: 1000px;
  height: 95vh;
  max-height: 800px;
  background-color: var(--mac-beige);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 
    0 20px 50px rgba(0, 0, 0, 0.4),
    0 0 0 2px rgba(0, 0, 0, 0.1);
}

/* Top section of the Macintosh */
.mac-top-bezel {
  height: 40px;
  background-color: var(--mac-beige);
  border-radius: 10px 10px 0 0;
  border-bottom: 2px solid var(--mac-darkest-beige);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 5px;
}

/* Apple logo on top */
.mac-top-logo {
  width: 20px;
  height: 22px;
  position: relative;
}

.mac-top-logo::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--mac-darkest-beige);
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512'%3E%3Cpath d='M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z'/%3E%3C/svg%3E");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

/* Screen section */
.mac-screen-container {
  height: auto;
  min-height: 600px; /* Minimum height for the entire screen */
  max-height: calc(100vh - 100px);
}

.mac-screen {
  flex: 1;
  background-color: var(--mac-screen-bg);
  border: 2px solid var(--mac-black);
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 0;
}

/* Bottom section with vents and logo */
.mac-computer-base {
  height: 80px;
  background-color: var(--mac-beige);
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  padding-top: 10px;
}

/* Vents on the bottom */
.mac-vents {
  display: flex;
  width: 50%;
  justify-content: center;
  margin-bottom: 10px;
}

.mac-vent {
  width: 5px;
  height: 25px;
  background-color: var(--mac-darkest-beige);
  margin: 0 3px;
  border-radius: 2px;
}

/* Name plate on the bottom */
.mac-name-plate {
  font-family: 'Chicago', sans-serif;
  font-size: 12px;
  color: var(--mac-gray);
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Disk drive */
.mac-disc-drive {
  position: absolute;
  width: 100px;
  height: 6px;
  background-color: var(--mac-darkest-beige);
  left: 30px;
  top: 25px;
  border-radius: 1px;
}

/* CRT screen effect (optional) */
.mac-crt-effect {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    rgba(255, 255, 255, 0.03) 50%, 
    rgba(0, 0, 0, 0.03) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
  z-index: 10;
  opacity: 0.3;
}

.mac-screen-glare {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    ellipse at top right,
    rgba(255, 255, 255, 0.15),
    transparent 70%
  );
  pointer-events: none;
  z-index: 11;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mac-computer-frame {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }
  
  .mac-top-bezel {
    border-radius: 0;
  }
  
  .mac-computer-base {
    border-radius: 0;
  }
  
  .mac-theme {
    padding: 0;
  }
}

/* Computer Frame */
.mac-computer-frame {
  width: 100%;
  max-width: 100%; /* Remove max-width limitation */
  min-height: 100vh; /* Make it fill the viewport height */
  margin: 0; /* Remove margin */
  border-radius: 0; /* Remove rounded corners */
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Computer Screen */
.mac-screen {
  flex: 1; /* Make it take available space */
  min-height: 0; /* Override the fixed min-height */
  display: flex;
  flex-direction: column;
  border-radius: 8px; /* Keep slight rounding on the screen */
}

/* Menu Bar */
.mac-menu-bar {
  display: flex;
  background-color: var(--mac-white);
  border-bottom: 1px solid var(--mac-black);
  padding: 2px 5px;
  height: 20px;
  margin-bottom: 5px;
}

.mac-apple-menu {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.mac-apple-logo {
  display: inline-block;
  width: 13px;
  height: 14px;
  position: relative;
  border-radius: 1px;
}

.mac-apple-logo::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  background-color: var(--mac-black);
  mask-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z'/%3E%3C/svg%3E");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

/* Mac Context Menu Styles */
.mac-context-menu {
  position: fixed;
  background-color: var(--mac-white);
  border: 1px solid var(--mac-black);
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.2);
  width: 200px;
  z-index: 1000;
  animation: mac-menu-appear 0.1s ease-out;
  transform-origin: top left;
}

@keyframes mac-menu-appear {
  from { transform: scale(0.95); opacity: 0.8; }
  to { transform: scale(1); opacity: 1; }
}

.mac-context-menu .mac-menu-item {
  padding: 4px 20px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  font-family: 'Chicago', 'Pixelated MS Sans Serif', 'Monaco', 'VT323', monospace;
  user-select: none;
}

.mac-context-menu .mac-menu-item:hover:not(.mac-menu-item-disabled) {
  background-color: var(--mac-black);
  color: var(--mac-white);
}

.mac-context-menu .mac-menu-item-disabled {
  color: var(--mac-gray);
  cursor: default;
}

.mac-context-menu .mac-menu-divider {
  height: 1px;
  background-color: var(--mac-black);
  margin: 3px 1px;
}

.mac-menu-items {
  display: flex;
  gap: 15px;
}

.mac-menu-items span {
  font-size: 12px;
  cursor: pointer;
}

.mac-menu-items span:hover {
  background-color: var(--mac-black);
  color: var(--mac-white);
}

/* Ensure the mac-page class is properly set up for mobile */
.mac-home-page {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

/* Desktop Content */
.mac-desktop-content {
  position: relative;
  min-height: 570px; /* Keep as a minimum baseline */
  height: calc(100vh - 150px); /* Dynamic height based on viewport height */
  overflow: hidden;
}

/* Windows */
.mac-window {
  background-color: var(--mac-white);
  border: 1px solid var(--mac-window-border);
  box-shadow: 4px 4px 0 var(--mac-shadow);
  position: absolute;
  width: 90%;
  max-width: 600px;
  overflow: hidden;
}

.mac-welcome-window {
  left: 5%;
  top: 30px;
  z-index: 3;
  max-width: 500px;
}

.mac-skills-window {
  left: 10%;
  top: 100px;
  z-index: 2;
}

.mac-projects-window {
  left: 15%;
  top: 150px;
  z-index: 1;
  max-width: 650px;
}

.mac-contact-window {
  left: 20%;
  top: 200px;
  z-index: 0;
}

.mac-window-title-bar {
  background-color: var(--mac-white);
  border-bottom: 1px solid var(--mac-window-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 8px;
  cursor: move;
}

.mac-window-title {
  font-size: 12px;
  color: var(--mac-window-title);
  text-align: center;
  flex-grow: 1;
}

.mac-window-controls {
  display: flex;
  gap: 4px;
}

.mac-window-close {
  width: 12px;
  height: 12px;
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
  position: relative;
  cursor: pointer;
}

.mac-window-close::before,
.mac-window-close::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 1px;
  background-color: var(--mac-black);
  top: 5px;
  left: 1px;
}

.mac-window-close::before {
  transform: rotate(45deg);
}

.mac-window-close::after {
  transform: rotate(-45deg);
}

.mac-window-content {
  padding: 12px;
  max-height: 450px;
  overflow-y: auto;
}

/* Welcome Section */
.mac-welcome-content {
  text-align: center;
  padding: 20px;
}

.mac-welcome-title {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.mac-welcome-title span {
  display: inline-block;
  background-color: var(--mac-black);
  color: var(--mac-white);
  padding: 0 5px;
}

.mac-welcome-text {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.4;
}

.mac-welcome-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Skills Section */
.mac-skills-content {
  padding: 10px;
}

.mac-section-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: 1px;
}

.mac-skills-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.mac-skill-category {
  border: 1px solid var(--mac-black);
  padding: 10px;
}

.mac-skill-title {
  font-size: 14px;
  margin-bottom: 8px;
  text-decoration: underline;
}

.mac-skill-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mac-skill-item {
  font-size: 12px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.mac-bullet {
  margin-right: 5px;
  font-size: 8px;
}

/* Projects Section */
.mac-projects-content {
  padding: 10px;
}

.mac-projects-description {
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
}

.mac-project-grid {
  margin-bottom: 15px;
}

.mac-view-more {
  display: flex;
  justify-content: center;
}

/* Contact Section */
.mac-contact-content {
  padding: 10px;
}

.mac-contact-description {
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
}

/* Desktop Icons */
.mac-desktop-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.mac-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  cursor: pointer;
}

.mac-icon:hover .mac-icon-label {
  background-color: var(--mac-black);
  color: var(--mac-white);
}

.mac-icon-image {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
}

.mac-icon-folder {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Cpath d='M3 5C3 3.89543 3.89543 3 5 3H9L11 5H19C20.1046 5 21 5.89543 21 7V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.mac-icon-document {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Cpath d='M13 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V9L13 2Z'%3E%3C/path%3E%3Cpath d='M13 2V9H20'%3E%3C/path%3E%3C/svg%3E");
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.mac-icon-application {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Crect x='2' y='2' width='20' height='20' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='12' y1='6' x2='12' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='12' x2='18' y2='12'%3E%3C/line%3E%3C/svg%3E");
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.mac-icon-mail {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Cpath d='M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z'%3E%3C/path%3E%3Cpolyline points='22,6 12,13 2,6'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}
.mac-icon-home {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Cpath d='M4 10V20H20V10'%3E%3C/path%3E%3Cpath d='M2 10L12 2 22 10'%3E%3C/path%3E%3Cpath d='M9 20V14H15V20'%3E%3C/path%3E%3C/svg%3E");
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.mac-icon-label {
  font-size: 12px;
  text-align: center;
  width: 100%;
  padding: 2px;
}

/* Button Styles */
.mac-button {
  display: inline-block;
  min-width: 100px;
  padding: 5px 15px;
  font-size: 12px;
  text-align: center;
  background-color: var(--mac-white);
  border: 1px solid var(--mac-black);
  border-radius: 0;
  box-shadow: 1px 1px 0 var(--mac-black);
  cursor: pointer;
  text-decoration: none;
  color: var(--mac-black);
}

.mac-button:active {
  transform: translate(1px, 1px);
  box-shadow: none;
}

.mac-button-outline {
  background-color: transparent;
}

/* Computer Base */
.mac-computer-base {
  height: 40px;
  background-color: var(--mac-beige);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 2px solid var(--mac-darkest-beige);
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mac-logo {
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--mac-gray);
}

/* Boot Screen */
.mac-boot-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--mac-white);
}

.mac-boot-icon {
  width: 60px;
  height: 60px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Crect x='2' y='3' width='20' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='2' y1='8' x2='22' y2='8'%3E%3C/line%3E%3Cline x1='12' y1='12' x2='12' y2='16'%3E%3C/line%3E%3C/svg%3E");
  background-size: contain;
  margin-bottom: 30px;
}

.mac-boot-progress-container {
  width: 200px;
  height: 10px;
  border: 1px solid var(--mac-black);
  overflow: hidden;
}

.mac-boot-progress-bar {
  height: 100%;
  background-color: var(--mac-black);
  transition: width 0.1s ease-in-out;
}

/* Custom Scrollbar */
.mac-window-content::-webkit-scrollbar {
  width: 16px;
}

.mac-window-content::-webkit-scrollbar-track {
  background-color: var(--mac-darker-beige);
  border: 1px solid var(--mac-black);
}

.mac-window-content::-webkit-scrollbar-thumb {
  background-color: var(--mac-beige);
  border: 1px solid var(--mac-black);
}

.mac-window-content::-webkit-scrollbar-button {
  background-color: var(--mac-beige);
  border: 1px solid var(--mac-black);
  height: 16px;
}

.mac-window-content::-webkit-scrollbar-button:start:decrement {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2'%3E%3Cpolyline points='18 15 12 9 6 15'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}

.mac-window-content::-webkit-scrollbar-button:end:increment {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='2'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
}

/* Add Chicago font */
@font-face {
  font-family: 'Chicago';
  src: url('https://fonts.cdnfonts.com/s/32030/ChicagoFLF.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* Mobile Responsive Adjustments */
@media (max-width: 768px) {
  .mac-skills-grid {
    grid-template-columns: 1fr;
  }
  .mac-home-page .mac-welcome-window,
  .mac-home-page .mac-skills-window,
  .mac-home-page .mac-projects-window,
  .mac-home-page .mac-contact-window,
  .mac-home-page .mac-about-window {
    position: relative;
    left: 0;
    top: 0;
    margin-bottom: 15px;
    width: 100%;
    max-width: 100%;
  }
  
  .mac-desktop-content {
    position: relative;
  min-height: 570px; /* Keep as a minimum baseline */
  height: calc(100vh - 150px); /* Dynamic height based on viewport height */
  overflow: auto;
  }
  
  .mac-desktop-icons {
    position: relative;
    flex-direction: row;
    justify-content: center;
    top: 0;
    right: 0;
    margin: 15px 0;
  }
  
  .mac-computer-frame {
    padding: 10px;
  }
}
/* Macintosh Project Card Styling */
.mac-project-card {
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.mac-project-image {
  height: 120px;
  background-color: var(--mac-light-gray);
  border-bottom: 1px solid var(--mac-black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mac-project-image-placeholder {
  font-size: 32px;
  font-weight: bold;
  color: var(--mac-white);
}

.mac-project-content {
  padding: 10px;
}

.mac-project-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-decoration: underline;
}

.mac-project-description {
  font-size: 12px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.mac-project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.mac-project-tag {
  font-size: 10px;
  padding: 2px 4px;
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
}

.mac-project-links {
  display: flex;
  gap: 10px;
  margin-top: auto;
}

.mac-project-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: var(--mac-black);
}

.mac-project-link:hover {
  text-decoration: underline;
}

.mac-project-icon {
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

/* Grid layout for projects */
.mac-project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

@media (max-width: 768px) {
  .mac-project-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .mac-project-grid {
    grid-template-columns: 1fr;
  }
}

/* Macintosh Contact Form Styling */
.mac-form {
  max-width: 100%;
}

.mac-form-group {
  margin-bottom: 12px;
}

.mac-form-label {
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
}

.mac-form-input,
.mac-form-textarea {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  font-family: 'Chicago', 'Pixelated MS Sans Serif', 'Monaco', 'VT323', monospace;
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
  border-radius: 0;
}

.mac-form-textarea {
  resize: vertical;
  min-height: 80px;
}

.mac-form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.mac-form-note {
  font-size: 10px;
  color: var(--mac-gray);
}

.mac-button-icon {
  display: inline-block;
  margin-left: 5px;
  width: 12px;
  height: 12px;
}

.mac-alert {
  margin-top: 15px;
  padding: 8px;
  font-size: 12px;
  border: 1px solid var(--mac-black);
}

.mac-alert-success {
  background-color: var(--mac-white);
}

.mac-folder-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1'%3E%3Cpath d='M3 5C3 3.89543 3.89543 3 5 3H9L11 5H19C20.1046 5 21 5.89543 21 7V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mac-folder-name {
  font-size: 12px;
}

.mac-text-file {
  font-family: 'Monaco', 'Courier New', monospace;
  font-size: 12px;
  line-height: 1.5;
  white-space: pre-wrap;
}

/* Projects page styles */
.mac-projects-main-window {
  width: 75%;
  max-width: 700px;
}

.mac-project-details-window {
  width: 40%;
  max-width: 300px;
}

.mac-filter-section {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.mac-filter-label {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 10px;
}

.mac-filter-icon {
  margin-right: 5px;
}

.mac-tag-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.mac-tag-button {
  font-size: 10px;
  padding: 2px 5px;
  background-color: var(--mac-white);
  border: 1px solid var(--mac-black);
  cursor: pointer;
}

.mac-tag-button-active {
  background-color: var(--mac-black);
  color: var(--mac-white);
}

.mac-projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.mac-project-card {
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
}

.mac-project-preview {
  height: 120px;
  background-color: var(--mac-light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--mac-black);
}

.mac-project-image-placeholder {
  font-size: 24px;
  color: var(--mac-dark-gray);
  font-weight: bold;
}

.mac-project-info {
  padding: 10px;
}

.mac-project-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.mac-project-description {
  font-size: 12px;
  margin-bottom: 8px;
  height: 60px;
  overflow: hidden;
}

.mac-project-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.mac-project-tag {
  font-size: 10px;
  padding: 1px 5px;
  background-color: var(--mac-white);
  border: 1px solid var(--mac-black);
}

.mac-project-links {
  display: flex;
  gap: 10px;
}

.mac-project-link {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--mac-black);
  text-decoration: none;
}

.mac-project-link:hover {
  text-decoration: underline;
}

.mac-link-icon {
  margin-right: 3px;
}

/* Blog page styles */
.mac-blog-main-window {
  width: 70%;
  max-width: 650px;
}

.mac-blog-archives-window {
  width: 30%;
  max-width: 200px;
}

.mac-blog-subscribe-window {
  width: 50%;
  max-width: 350px;
}

.mac-blog-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
  justify-content: center;
}

.mac-category-button {
  min-width: auto;
  padding: 3px 8px;
  font-size: 12px;
}

.mac-blog-posts {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.mac-blog-post {
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
  display: flex;
  flex-direction: column;
}

.mac-blog-image {
  height: 120px;
  background-color: var(--mac-light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--mac-black);
}

.mac-blog-image-placeholder {
  font-size: 20px;
  color: var(--mac-dark-gray);
  padding: 0 20px;
  text-align: center;
}

.mac-blog-content {
  padding: 10px;
}

.mac-blog-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 8px;
}

.mac-blog-meta-item {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: var(--mac-gray);
}

.mac-blog-meta-icon {
  margin-right: 3px;
}

.mac-blog-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.mac-blog-excerpt {
  font-size: 12px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mac-blog-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.mac-blog-tag {
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 1px 5px;
  background-color: var(--mac-white);
  border: 1px solid var(--mac-black);
}

.mac-blog-tag-icon {
  margin-right: 3px;
  width: 10px;
  height: 10px;
}

.mac-read-more-button {
  align-self: flex-start;
  font-size: 11px;
}

.mac-subscribe-form {
  padding: 10px;
}

.mac-subscribe-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: center;
}

.mac-subscribe-text {
  font-size: 12px;
  margin-bottom: 15px;
  text-align: center;
}

.mac-input-with-button {
  display: flex;
  gap: 5px;
}

.mac-privacy-note {
  font-size: 10px;
  color: var(--mac-gray);
  margin-top: 10px;
  text-align: center;
}

/* Contact page styles */
.mac-contact-form-window {
  width: 60%;
  max-width: 500px;
}

.mac-contact-info-window {
  width: 40%;
  max-width: 300px;
}

.mac-response-time-window {
  width: 45%;
  max-width: 350px;
}

.mac-contact-info {
  padding: 15px;
}

.mac-contact-item {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start;
}

.mac-contact-icon {
  margin-right: 10px;
  font-size: 16px;
  color: var(--mac-black);
}

.mac-contact-detail {
  flex-grow: 1;
}

.mac-contact-label {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px;
}

.mac-contact-value {
  font-size: 12px;
  color: var(--mac-black);
  text-decoration: none;
}

.mac-contact-value:hover {
  text-decoration: underline;
}

.mac-social-links {
  margin-top: 20px;
  border-top: 1px solid var(--mac-light-gray);
  padding-top: 15px;
}

.mac-social-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.mac-social-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.mac-social-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--mac-black);
}

.mac-social-icon {
  width: 24px;
  height: 24px;
  padding: 5px;
  border: 1px solid var(--mac-black);
  background-color: var(--mac-white);
  margin-bottom: 5px;
}

.mac-social-button:hover .mac-social-icon {
  background-color: var(--mac-black);
  color: var(--mac-white);
}

/* 404 Page styles */
.mac-error-window {
  width: 60%;
  max-width: 450px;
}

.mac-system-error-window {
  width: 50%;
  max-width: 400px;
}

.mac-error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.mac-error-icon {
  margin-bottom: 20px;
}

.mac-bomb-icon {
  font-size: 48px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.mac-error-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 5px;
}

.mac-error-subtitle {
  font-size: 18px;
  margin-bottom: 15px;
}

.mac-error-text {
  font-size: 14px;
  margin-bottom: 20px;
}

.mac-error-dialog {
  border: 1px solid var(--mac-black);
  padding: 15px;
  width: 100%;
  max-width: 300px;
  background-color: var(--mac-white);
}

.mac-error-dialog-text {
  font-size: 12px;
  margin-bottom: 15px;
}

.mac-error-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.mac-error-log {
  font-family: 'Monaco', 'Courier New', monospace;
  font-size: 12px;
  padding: 10px;
}

/* Responsive Design */
@media (max-width: 992px) {
  .mac-skills-main-window,
  .mac-skills-categories-window,
  .mac-skills-readme-window,
  .mac-projects-main-window,
  .mac-project-details-window,
  .mac-blog-main-window,
  .mac-blog-archives-window,
  .mac-blog-subscribe-window,
  .mac-contact-form-window,
  .mac-welcome-window,
  .mac-contact-info-window,
  .mac-response-time-window,
  .mac-skills-window,
  .mac-projects-window,
  .mac-contact-window,
  .mac-about-window,
  .mac-contact-info-window,
  .mac-response-time-window,
  .mac-error-window,
  .mac-system-error-window {
    position: relative;
    top: auto !important;
    left: auto !important;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
    z-index: 1 !important;
  }
  
  .mac-page {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
  }
  
  .mac-projects-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .mac-skills-categories {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
  }
  
  .mac-skill-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .mac-skill-level-wrapper {
    width: 100%;
    align-items: flex-start;
    margin-top: 5px;
  }
  
  .mac-blog-post {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .mac-projects-grid {
    grid-template-columns: 1fr;
  }
  
  .mac-social-buttons {
    flex-wrap: wrap;
  }
  
  .mac-menu-items span {
    display: none;
  }
  
  .mac-menu-clock {
    font-size: 10px;
  }
  
  .mac-apple-logo {
    margin-right: 0;
  }
  
  .mac-input-with-button {
    flex-direction: column;
  }
}